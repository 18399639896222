import "./ProductDetail.scss";
import { createMemo, createResource, createSignal, Show } from "solid-js";
import { getFullProduct } from "../stores/shop.store";
import { createEffect } from "solid-js";
import { Select } from "@kobalte/core/select";
import { ProductImage } from "./ProductImage";
import { Variation } from "../../googleScripts/sheet/types";
import { addCartItem, cartStore, isReady } from "../stores/cart.store";
import { PromoPrice } from "./PromoPrice";
import { Loader } from "./Loader";
import Info from "./Info";
import IconCircleCheck from "@tabler/icons-solidjs/dist/esm/icons/IconCheck.mjs";

import IconCaretDownFilled from "@tabler/icons-solidjs/dist/esm/icons/IconCaretDownFilled.mjs";
import IconCheck from "@tabler/icons-solidjs/dist/esm/icons/IconCheck.mjs";
import { ProductAvailability } from "./ProductAvailability";
import { ProductImageGallery } from "./ProductImageGallery";

function ProductDetail({
  categoryName,
  productSlug,
}: {
  categoryName: string;
  productSlug: string;
}) {
  const productResource = createResource(
    { categoryName: categoryName, productSlug: productSlug },
    getFullProduct
  );

  const product = () => {
    return productResource[0]();
  };

  const variations = () => {
    return product()?.variations;
  };

  const [selectedVariationName, setSelectedVariationName] =
    createSignal<string>();
  const [variation, setVariation] = createSignal<Variation>();

  const correspondingCartItem = createMemo(() => {
    return cartStore.cartItems.find(
      (item) => item.variationId === variation()?.id
    );
  });

  const variationNames = createMemo(() => {
    return variations()?.map((v) => v.name) || [];
  });

  const productProps = createMemo(() => {
    return [
      product()?.type ? { key: "type", value: product()?.type } : null,
      ...(product()?.custom_props || []),
      product()?.bio ? { key: "biologisch", value: "ja" } : null,
    ].filter((p: any) => p?.key && p?.value);
  });

  const hasMultipleVariations = createMemo(() => {
    //@ts-ignore
    return variations()?.length && variations()?.length > 1;
  });

  const variationProps = createMemo(() => {
    const o = [...(variation()?.custom_props || [])];

    if (!hasMultipleVariations() && variation()?.name) {
      o.push({ key: "formaat", value: variation()?.name });
    }

    return o.filter((p: any) => p?.key && p?.value);
  });

  createEffect(() => {
    setSelectedVariationName(variationNames()[0]);
  });

  createEffect(() => {
    setVariation(variations()?.find((v) => v.name === selectedVariationName()));
  });

  const isInCart = createMemo(() => correspondingCartItem());

  const canBeAddedToCart = createMemo(() => {
    // if (isInCart()) return isInCart().canBeOrdered;
    // if (correspondingCartItem()) return correspondingCartItem().canBeOrdered;
    if (correspondingCartItem()) return false;

    if (variation()?.upcoming) return false;

    if (!isReady()) return false;
    //@ts-ignore
    return variation()?.stock > 0;
  });

  return (
    <div class="productDetail">
      <Show
        when={!productResource[0].loading}
        fallback={
          <div class="centeredPageBlock">
            <Loader label="" />
          </div>
        }
      >
        <Show
          when={variation()}
          fallback={
            <div class="centeredPageBlock">
              <Info
                message={`Product "${productSlug}" niet gevonden`}
                type="info"
              />
            </div>
          }
        >
          <div class="productDetail_header">
            <h1 class="productDetail_name">
              {product()?.name || "product name"}
            </h1>
            {product()?.alt_name && (
              <h2 class="productDetail_altName">{product()?.alt_name}</h2>
            )}
          </div>
          <div class="productDetail_image">
            <ProductImageGallery
              images={product()?.images || []}
              category={categoryName}
            />
            {/* {product()?.images?.map((imageName) => (
              <ProductImage
                category={categoryName}
                // slug={product()?.slug}
                imageName={imageName}
              />
            ))} */}
            {/* <ProductImage category={categoryName} slug={product()?.slug} /> */}
          </div>
          <div class="productDetail_aside">
            {hasMultipleVariations() && (
              <Select
                value={selectedVariationName()}
                onChange={(v) => v && setSelectedVariationName(v)}
                options={variationNames()}
                placeholder="Selecteer een type"
                itemComponent={(props) => (
                  <Select.Item item={props.item} class="select__item">
                    {/* @ts-ignore */}
                    <Select.ItemLabel>{props.item.rawValue}</Select.ItemLabel>
                    <Select.ItemIndicator class="select__item-indicator">
                      <IconCheck />
                    </Select.ItemIndicator>
                  </Select.Item>
                )}
              >
                <Select.Trigger aria-label="type" class="select__trigger">
                  <Select.Value<string> class="select__value">
                    {(state) => state.selectedOption()}
                  </Select.Value>
                  <Select.Icon class="select__icon">
                    <IconCaretDownFilled size={18} />
                  </Select.Icon>
                </Select.Trigger>
                <Select.Portal>
                  <Select.Content class="select__content">
                    <Select.Listbox class="select__listbox" />
                  </Select.Content>
                </Select.Portal>
              </Select>
            )}

            <PromoPrice
              price={variation().price}
              promoString={variation().promo}
            />

            {/* {variation()?.upcoming && <ProductStatus status="upcoming" />} */}

            {variationProps()?.length && (
              <table class="productDetail_customProps">
                {variationProps()?.map((cp) => (
                  <tr class="productDetail_custom_prop">
                    {/* @ts-ignore */}
                    <td>{cp?.key}</td>
                    {/* @ts-ignore */}
                    <td>{cp?.value}</td>
                  </tr>
                ))}
              </table>
            )}

            <ProductAvailability
              status={
                variation()?.upcoming
                  ? "upcoming"
                  : variation().stock > 0
                  ? "inStock"
                  : "outOfStock"
              }
            />

            <button
              class={
                "productDetail_btnAddToCart btn btn__fullWidth btn__primary" +
                (isInCart() ? " btn__success" : "")
              }
              disabled={!canBeAddedToCart()}
              onClick={() =>
                addCartItem({
                  product: product(),
                  variationId: variations().find(
                    (v) => v.name === selectedVariationName()
                  )?.id,
                  amount: 1,
                })
              }
            >
              {isInCart() ? (
                <div class="productDetail_btnAddToCart__inCart">
                  <IconCircleCheck size={22} /> <span>toegevoegd</span>
                </div>
              ) : (
                "Toevoegen aan kruiwagen"
              )}
            </button>
          </div>

          <div class="productDetail_props">
            {productProps()?.length && (
              <>
                <h2>Eigenschappen</h2>
                <table class="productDetail_customProps">
                  {productProps()?.map((cp) => (
                    <tr class="productDetail_custom_prop">
                      {/* @ts-ignore */}
                      <td>{cp?.key}</td>
                      {/* @ts-ignore */}
                      <td>{cp?.value}</td>
                    </tr>
                  ))}
                </table>

                {/* //bio logo */}
                {product()?.bio && (
                  <div class="productDetail_logos">
                    <img
                      src="/images/logo-bio.png"
                      alt="bio logo"
                      class="productDetail_logo"
                    />
                  </div>
                )}
              </>
            )}
          </div>
          <div class="productDetail_description">
            <h2>Omschrijving</h2>
            <div innerHTML={product()?.description}></div>
          </div>
        </Show>
      </Show>
    </div>
  );
}

export default ProductDetail;
