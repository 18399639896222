import "./OrderReviewTable.scss";
import { totalPrice, cartStore } from "../stores/cart.store";
import { calcPromoPrice } from "../../googleScripts/sheet/lib/promo";

function OrderReviewTable() {
  return (
    <div class="orderReviewTable">
      <table>
        <thead>
          <tr>
            <th>product</th>
            <th>aantal</th>
            <th>prijs/eenheid</th>
            <th>prijs</th>
          </tr>
        </thead>
        <tbody>
          {cartStore.cartItems
            .filter((item) => item.amount > 0)
            .map((item) => {
              const variation = item.product.variations.find(
                (v) => v.id === item.variationId
              );

              const promoPrice = calcPromoPrice(
                variation.price,
                variation?.promo
              );

              return (
                <tr>
                  <td>{item.product.name}</td>
                  <td>
                    {item.amount}{" "}
                    {item.freeUnits && <span>+ {item.freeUnits} gratis</span>}
                  </td>
                  <td class={promoPrice ? "orderReviewTable__withPromo" : ""}>
                    <span class="orderReviewTable_standardPrice">
                      {variation.price}
                    </span>
                    {promoPrice ? (
                      <span class="orderReviewTable_promoPrice">
                        {promoPrice}
                      </span>
                    ) : (
                      ""
                    )}{" "}
                    €
                  </td>
                  <td>{item.price.toFixed(2)} €</td>
                </tr>
              );
            })}

          <tr class="orderReviewTable_total">
            <td></td>
            <td></td>
            <td class="orderReviewTable_totalLabel">totaal</td>
            <td>{totalPrice().toFixed(2)} €</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
export default OrderReviewTable;
