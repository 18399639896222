import "./CheckoutForm.scss";
import "pikaday/css/pikaday.css";

import Callout from "./Callout";
import OrderReviewTable from "./OrderReviewTable";
import { setUserStore, userStore } from "../stores/user.store";
import { orderStore, sendOrder } from "../stores/order.store";
import OrderSuccess from "./OrderSuccess";
import { cartStore } from "../stores/cart.store";
import { Loader } from "./Loader";
import { createSignal, onMount } from "solid-js";
import Pikaday from "pikaday";
import { daysClosed, daysOpen } from "../stores/shop.store";

import {
  formatShortDate,
  dateStringToDate,
  isOnSameDay,
} from "../../shared/date-utils";

function CheckoutForm() {
  const [formIsValid, setFormIsValid] = createSignal(true);

  const handleSubmit = async (e: Event) => {
    e.preventDefault();

    const form = e.target as HTMLFormElement;

    // Check form validity before submitting
    if (checkFormValidity(form)) {
      await sendOrder();
    }
  };

  function checkFormValidity(form: HTMLFormElement) {
    let isValid = true;

    Array.from(form.elements).forEach((element) => {
      if (
        !(
          element instanceof HTMLInputElement ||
          element instanceof HTMLTextAreaElement ||
          element instanceof HTMLSelectElement
        )
      ) {
        return;
      }

      const valid = handleInputValidation(element);
      if (valid === false) isValid = false;
    });

    setFormIsValid(isValid);

    return isValid;
  }

  function handleInputValidation(
    element: HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
  ) {
    const el_container = element.parentElement;
    el_container.classList.remove("input__error");
    element.setCustomValidity("");
    if (!element.checkValidity()) {
      element.setCustomValidity(element.title || "Dit veld is verplicht");

      el_container.classList.add("input__error");

      const el_error =
        el_container.getElementsByClassName("inputErrorMessage")?.[0];
      if (el_error) {
        el_error.innerHTML = element.validationMessage;
      }
      return false;
    }

    return true;
  }

  const storeInput = (e: InputEvent) => {
    const { value, name } = e.currentTarget as HTMLInputElement;
    setUserStore({ ...userStore, [name]: value });
    // handleInputValidation(e.currentTarget as HTMLInputElement);
  };

  const validateDateInput = (e) => {
    const input = e.currentTarget;
    const datePattern = /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/;
    const value = input.value;

    if (!datePattern.test(value))
      return input.setCustomValidity(
        "Gelieve een datum in te voeren met formaat dd/mm/jjjj"
      );

    if (!validateDate(dateStringToDate(value))) {
      return input.setCustomValidity("Op deze datum kan je niet afhalen");
    }

    input.setCustomValidity(""); // Clear custom validity if valid
  };

  //keep these signals seperated so they do not potentially intermingel with pickadate functionality
  const OPEN_DAYS = daysOpen();
  const CLOSED_DAYS = daysClosed();

  function validateDate(date: Date) {
    // console.log("validateDate", date);

    const today = new Date();
    today.setHours(0, 0, 0, 0);
    date.setHours(0, 0, 0, 0);

    //no date before today
    if (date.getTime() <= today.getTime()) return false;

    // console.log("closed days", daysClosed());
    //particular closed days
    for (const s of CLOSED_DAYS) {
      if (isOnSameDay(date, dateStringToDate(s))) return false;
      // if (date.getTime() === dateStringToDate(s).getTime()) return false;
    }

    // console.log("open days", daysOpen());
    //particular open days
    for (const s of OPEN_DAYS) {
      if (isOnSameDay(date, dateStringToDate(s))) return true;
    }

    //no sundays
    if (date.getDay() === 0) return false;

    return true;
  }

  onMount(() => {
    setTimeout(() => {
      new Pikaday({
        field: document.getElementById("pickupDate"),
        theme: "pickupDate_custom",
        format: "DD/MM/YYYY",
        firstDay: 1,
        keyboardInput: false, //let's you pick disabled dates...
        disableDayFn: (date: Date) => {
          return !validateDate(date);
        },
        onSelect: function (date: Date) {
          setUserStore({ ...userStore, pickupDate: formatShortDate(date) });
        },
        parse: dateStringToDate,
        toString(date: Date) {
          return formatShortDate(date);
        },
      });
    }, 500);
  });

  // /**
  //  *
  //  * @param {*} dateString dd/mm/jjjj formaat
  //  * @returns
  //  */
  // function dateStringToDate(dateString) {
  //   const [day, month, year] = dateString.split("/").map(Number);
  //   const d = new Date(Date.UTC(year, month - 1, day));
  //   d.setHours(0, 0, 0, 0);
  //   return d;
  // }

  return (
    <>
      {orderStore.state === "success" && <OrderSuccess />}
      {orderStore.state !== "success" && cartStore.cartItems.length > 0 && (
        <form
          onSubmit={handleSubmit}
          // onsubmit={handleSubmit}
          noValidate
          class={
            "checkoutForm " +
            (orderStore.state === "sending" ? "checkoutForm__sending" : "")
          }
        >
          <h2>Jouw gegevens</h2>
          <div class="checkoutForm_col">
            <div class="input">
              <label for="name" class="block">
                Voornaam *
              </label>
              <input
                id="name"
                name="name"
                value={userStore.name}
                onInput={storeInput}
                required
              />
              <div class="inputErrorMessage">A</div>
            </div>

            <div class="input">
              <label for="lastname" class="block">
                Achternaam *
              </label>
              <input
                id="lastname"
                name="lastname"
                value={userStore.lastname}
                onInput={storeInput}
                required
              />
              <div class="inputErrorMessage">A</div>
            </div>
          </div>

          <div class="input">
            <label for="company" class="block">
              Bedrijfsnaam (optioneel)
            </label>
            <input
              id="company"
              name="company"
              value={userStore.company}
              onInput={storeInput}
            />
            <div class="inputErrorMessage">A</div>
          </div>

          {userStore.company && (
            <div class="input">
              <label for="taxNr" class="block">
                Btw nummer
              </label>
              <input
                id="taxNr"
                name="taxNr"
                value={userStore.taxNr}
                onInput={storeInput}
                // required={!!userStore.company.length}
              />
              <div class="inputErrorMessage">A</div>
            </div>
          )}

          {userStore.company && (
            <div class="input">
              <label for="streetAndNumber" class="block">
                Straat en huisnummer *
              </label>
              <input
                id="streetAndNumber"
                name="streetAndNumber"
                value={userStore.streetAndNumber}
                onInput={storeInput}
                required
              />
              <div class="inputErrorMessage">A</div>
            </div>
          )}

          {userStore.company && (
            <div class="checkoutForm_col">
              <div class="input">
                <label for="city" class="block">
                  Plaats *
                </label>
                <input
                  id="city"
                  name="city"
                  value={userStore.city}
                  onInput={storeInput}
                  required
                />
                <div class="inputErrorMessage">A</div>
              </div>

              <div class="input">
                <label for="postcode" class="block">
                  Postcode *
                </label>
                <input
                  id="postcode"
                  name="postcode"
                  value={userStore.postcode}
                  onInput={storeInput}
                  required
                  // pattern="\d{4}\s"
                  title="Voer een geldige Belgische postcode in"
                />
                <div class="inputErrorMessage">A</div>
              </div>
            </div>
          )}

          <div class="input">
            <label for="telephone" class="block">
              Telefoon *
            </label>
            <input
              id="telephone"
              name="telephone"
              value={userStore.telephone}
              onInput={storeInput}
              required
              // shoul
              pattern="\d{10}"
              title="Voer een 10-cijferig telefoonnummer in"
            />
            <div class="inputErrorMessage">A</div>
          </div>

          <div class="input">
            <label for="email" class="block">
              E-mailadres *
            </label>
            <input
              id="email"
              name="email"
              type="email"
              value={userStore.email}
              onInput={storeInput}
              required
              title="Voer een geldige email adres in"
            />
            <div class="inputErrorMessage">A</div>
          </div>

          <div class="input">
            <label for="start">
              Welke dag zou je jouw plantgoed willen afhalen? *
            </label>
            <input
              type="text"
              id="pickupDate"
              name="pickupDate"
              onInput={storeInput}
              onBlur={validateDateInput}
              placeholder="dd/mm/jjjj"
              required
              autocomplete="off"
              title="Voer een geldige datum in"
            ></input>
            {/* <input
              type="date"
              id="pickupDate"
              name="pickupDate"
              // value={userStore.pickupDate}
              onInput={handleInput}
              min={today}
              required
            /> */}
            <div class="inputErrorMessage">A</div>
          </div>

          <div class="input">
            <label for="start">
              Rond welke tijd zou je jouw plantgoed willen afhalen? *
            </label>

            {/* <input
              type="time"
              id="pickupTime"
              name="pickupTime"
              onInput={handleInput}
              value={userStore.pickupTime}
              min="08:00"
              max="19:00"
              required
            /> */}

            <select
              name="pickupTime"
              id="pickupTime"
              onInput={storeInput}
              required
              // value={userStore.pickupTime}
              title="Kies een tijd"
            >
              <option value="" disabled selected hidden>
                Kies een tijd
              </option>
              <option value="09:00">09:00</option>
              <option value="09:30">09:30</option>
              <option value="10:00">10:00</option>
              <option value="10:30">10:30</option>
              <option value="11:00">11:00</option>
              <option value="11:30">11:30</option>
              <option value="13:00">13:00</option>
              <option value="13:30">13:30</option>
              <option value="14:00">14:00</option>
              <option value="14:30">14:30</option>
              <option value="15:00">15:00</option>
              <option value="15:30">15:30</option>
              <option value="16:00">16:00</option>
              <option value="16:30">16:30</option>
              <option value="17:00">17:00</option>
              <option value="17:30">17:30</option>
              <option value="18:00">18:00</option>
              <option value="18:30">18:30</option>
              <option value="19:00">19:00</option>
            </select>
            <div class="inputErrorMessage">A</div>
          </div>

          <div class="input">
            <label for="message" class="block">
              Bericht
            </label>
            <textarea
              id="message"
              name="message"
              value={userStore.message}
              onInput={storeInput}
              rows="4"
              maxLength={500}
            ></textarea>
            <div class="inputErrorMessage">A</div>
          </div>

          <div>
            <h2>Jouw bestelling</h2>
            <OrderReviewTable />
          </div>

          <Callout type="info" title="Opgelet">
            <span>
              Plantgoed wordt altijd afgehaald aan de kwekerij, wij versturen
              niet per post.
              <br />
              Betalen kan bij afhaling contant of met de bankkaart.
              <br />
              Breng een doos of bak mee om je plantgoed te vervoeren.
            </span>
          </Callout>

          <p>
            Je persoonlijke gegevens zullen worden gebruikt om je bestelling te
            verwerken en om je beleving op deze website te optimaliseren.
          </p>

          {!formIsValid() && (
            <Callout type="warning">
              <span>
                Kijk de gegevens in het formulier na. Zorg ervoor dat je alle
                gegevens correct invult.
              </span>
            </Callout>
          )}

          <div class="checkoutForm_col">
            <div></div>
            <button type="submit" class="checkoutForm_submit btn btn__primary">
              {orderStore.state === "sending" && <Loader color="white" />}
              Bestelling plaatsen
            </button>
          </div>
        </form>
      )}
      {orderStore.state !== "success" && cartStore.cartItems.length === 0 && (
        <>
          <h1>Jouw kruiwagen</h1>
          <div class="cart_empty">
            <Callout>Jouw kruiwagen is leeg</Callout>
            <a href="/" class="btn btn__primary">
              ga naar de producten
            </a>
          </div>
        </>
      )}
    </>
  );
}

export default CheckoutForm;
